import React, {
    PropsWithChildren,
    ReactElement,
    Ref,
    useCallback, useEffect,
    useRef,
    useState
} from 'react';
import TextInput from './TextInput';
import NumericInput from './NumericInput';
import {FormState, useFormContext} from "react-hook-form";
import DateInput from "@/app/components/atomic/Input/DateInput";
import SelectInput from "@/app/components/atomic/Input/SelectInput";
import AppTooltip from "@/app/components/atomic/molecules/AppTooltip";
import {InputMaskProps} from "@react-input/mask";
import SimpleSelectInput from "@/app/components/atomic/Input/SimpleSelectInput";

export interface GenericInputReturn<T = string> {
    value: T;
    fieldName: string;
}

export interface GenericInputProps<T = string> {
    fieldName: string;
    onChange: (inputReturn: GenericInputReturn) => void;
    inputProps?: any;
    value: string | number | undefined | null;
}

interface AppInputProps extends GenericInputProps{
    type?: any;
    min?: number;
    max?: number;
    addonContent?: string | ReactElement;
    prefixContent?: AppInputProps["addonContent"];
    addonTheme?: IAddonContentProps["theme"];
    required?: boolean;
    debounce?: number | null;
    placeholder?: string;
    mask?: InputMaskProps["mask"];
    replacement?: InputMaskProps["replacement"];
    track?: InputMaskProps["track"];
    validation?: boolean;
    ref?: Ref<any>;
}

export const TYPE_TEXT = 'text';
export const TYPE_TEXT_NUMBER = 'text/number';

export const INPUT_TYPE_NUMBER = 'number';
export const INPUT_TYPE_DATE = 'date';
export const INPUT_TYPE_SELECT = 'select';
export const INPUT_TYPE_SIMPLE_SELECT = 'simple-select';
const TYPE_COMPONONENT_MAP: {[key: string]: any} = {
    [TYPE_TEXT]: TextInput,
    [INPUT_TYPE_NUMBER]: NumericInput,
    [INPUT_TYPE_DATE]: DateInput,
    [INPUT_TYPE_SELECT]: SelectInput,
    [INPUT_TYPE_SIMPLE_SELECT]: SimpleSelectInput,
} as const

function AppInput({
    value,
    onChange,
    fieldName,
    addonContent,
    prefixContent,
    addonTheme,
    required,
    min,
    max,
    debounce,
    placeholder,
    validation = undefined,
    type = TYPE_TEXT,
    ref,
    mask,
    replacement,
    track,
    inputProps = {},
}: AppInputProps) {
    const inputRef = useRef(null);
    const [isFocused, setIsFocused] = useState(false);
    const {
        formState = {},
        setValue = () => {},
    }: { formState: FormState<any> | {}, setValue: Function } = useFormContext() || {}

    useEffect(() => {
        setValue(fieldName, value)
    }, []);

    const onChangeWithValidation = (inputReturn: GenericInputReturn) => {
        setValue(fieldName, (inputReturn as any).value)
        onChange(inputReturn)
    }
    const renderInput = () => {

        if (Object.keys(TYPE_COMPONONENT_MAP).includes(type)) {
            const InputComponent = TYPE_COMPONONENT_MAP[type]
            return (
                <InputComponent
                    value={value || ""}
                    inputRef={inputRef}
                    onChange={onChangeWithValidation}
                    fieldName={fieldName}
                    required={required}
                    debounce={debounce}
                    placeholder={placeholder}
                    validation={validation}
                    inputProps={inputProps}
                    mask={mask}
                    replacement={replacement}
                    track={track}
                    className={`${prefixContent ? 'pl-0' : ''}`}
                />
            );
        } else {
            return (
                <NumericInput
                    value={value}
                    onChange={onChangeWithValidation}
                    fieldName={fieldName}
                    required={required}
                    min={min}
                    max={max}
                    debounce={debounce}
                />
            );
        }
    };

    const getError = useCallback(() => {
        const fieldErrors = (formState as FormState<any>)?.errors?.[fieldName as string]
        return fieldErrors
    }, [(formState as FormState<any>)?.errors])

    return (
        <div onFocus={() => setIsFocused(true)} onBlur={() => setIsFocused(false)} className={`flex relative bg-white items-center border border-blue-300 rounded w-full min-h-12 transition-all duration-500 hover:border-blue-500 focus-within:border-blue-500 ${getError() && 'border-red-500'}`}>
            {prefixContent && (
                <AddonContent theme={addonTheme}>
                    {prefixContent}
                </AddonContent>
            )}
            <AppTooltip
                isOpen={!!(isFocused && getError())}
                label={getError()?.message as string}
            >
                {renderInput()}
            </AppTooltip>
            {addonContent && (
                <AddonContent theme={addonTheme} place={"right"}>
                    {addonContent}
                </AddonContent>
            )}
        </div>
    );
}

interface IAddonContentProps extends PropsWithChildren {
    place?: 'left'|'right';
    theme?: keyof typeof ADDON_CONTENT_THEMES;
}

const ADDON_CONTENT_THEMES = {
    accent: 'bg-blue-100 border-t border-b border-inherit',
    blank: 'bg-none border-none',
}

const AddonContent = (props: IAddonContentProps) => {
    const { theme = 'accent' } = props
    return <div
        className={`flex items-center justify-center h-12 px-4 text-md font-semibold ${props.place === 'right' ? 'border-l' : 'border-r'} ${ADDON_CONTENT_THEMES[theme]}`}
    >
        {props.children}
    </div>
}

export default React.memo(AppInput)
