import {Ref, useEffect, useState} from "react";
import {GenericInputProps} from "@/app/components/atomic/Input/AppInput";
import AppDatePicker from "@/app/components/atomic/molecules/AppDatePicker";
import {useFormContext} from "react-hook-form";

interface DateInputProps extends GenericInputProps {
    value: string;
    required?: boolean;
    debounce?: number | null;
    placeholder?: string;
    validation?: boolean;
    ref?: Ref<any>;
    minDate: string;
}

const getFormattedDate = (date: string) => (date.length > 2 ? new Date(date) : new Date()).toISOString().split('T')[0]

export default function DateInput(props: DateInputProps) {
    const { setValue, register, formState: { errors }} = useFormContext()
    const [formattedDate, setFormattedDate] = useState(getFormattedDate(props.value))

    useEffect(() => {
        setFormattedDate(getFormattedDate(props.value))
        setValue?.(props.fieldName, props.value)
    }, [props.value]);

    return <AppDatePicker
        useIso
        value={formattedDate}
        {...register(props.fieldName)}
        minDate={props.inputProps.minDate}
        filterDate={(date) => {
            return ![0,6].includes(date.getDay())
        }}
        wrapperClassName={'border-0 outline-0 pl-0'}
        className={'pl-0'}
        onChange={(value: string) => {
            setFormattedDate(getFormattedDate(value))
            setValue?.(props.fieldName, value)
            props.onChange({ value, fieldName: props.fieldName })
        }}
    />
}
