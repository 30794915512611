'use client'
import React, {useState, ChangeEvent, useCallback, useEffect} from 'react';
import dynamic from 'next/dynamic';
import {GenericInputProps, TYPE_TEXT_NUMBER} from "@/app/components/atomic/Input/AppInput";

const NumericFormat = dynamic(() =>
    import('react-number-format').then((mod) => mod.NumericFormat), { ssr: false }
);

interface NumericInputProps extends GenericInputProps {
    value: string | number | undefined | null;
    min?: number;
    max?: number;
    required?: boolean;
    debounce?: number | null;
}

export default function NumericInput({
    value,
    onChange,
    fieldName,
    min,
    max,
    required,
    debounce,
}: NumericInputProps) {
    const [internalValue, setInternalValue] = useState<string>(value?.toString() || '');

    const debouncedOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const inputReturn = {
                value: e.target.value,
                fieldName,
            }
            if (debounce === null) {
                onChange(inputReturn)
            } else {
                const handler = setTimeout(() => {
                    onChange(inputReturn)
                }, debounce)

                return () => {
                    clearTimeout(handler)
                }
            }
        },
        [onChange, debounce]
    );

    const handleInputChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const inputReturn = {
                value: e.target.value,
                fieldName,
            }

            setInternalValue(e.target.value);
            onChange(inputReturn)
        },
        [setInternalValue, debouncedOnChange]
    );

    const handleMinAndMaxValues = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const value = parseFormattedNumber(e.target.value);
            if (isNaN(value)) {
                return e
            }

            if (min !== undefined && min !== null && value < min) {
                e.target.value = min.toString()
                handleInputChange(e)
            } else if (max !== undefined && max !== null && value > max) {
                e.target.value = max.toString()
                handleInputChange(e)
            }

        },
        [handleInputChange, min, max]
    );

    useEffect(() => {
        setInternalValue(value?.toString() || '');
    }, [value]);

    return (
        <NumericFormat
            type="tel"
            data-input-type={TYPE_TEXT_NUMBER}
            className="w-full px-3 focus:outline-none text-blue-800"
            name={fieldName}
            id={fieldName}
            required={required ?? false}
            autoFocus={false}
            thousandSeparator=" "
            value={internalValue}
            onBlur={handleMinAndMaxValues}
            onChange={handleInputChange}
        />
    );
}

function parseFormattedNumber(value: string): number {
    return parseFloat(value.replace(/\s/g, '').replace(',', '.'));
}
