import {PropsWithChildren} from "react";
import AppIcon from "@/app/components/atomic/AppIcon";

interface AppTooltipProps extends PropsWithChildren {
    isOpen?: boolean;
    label?: string;
}

export default function AppTooltip(props: AppTooltipProps) {
    return <>
        {props.children}
        {props.isOpen && <div
            className={`font-xs z-10 bg-red-500 rounded text-white px-4 py-2 absolute -top-full right-0 flex gap-4 select-none items-center`}
        >
            <AppIcon iconClassName={'fa-hexagon-exclamation'}/> {props.label}
            <div
                className={`border-x-4 border-x-transparent border-t-4 border-red-500 size-0 absolute top-full right-2`}></div>
        </div>}
    </>
}
