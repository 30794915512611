'use client'
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";

interface AppIconProps {
    className?: string
    hoveredClassName?: string
    iconClassName?: string
    type?: keyof typeof TYPES
    size?: keyof typeof SIZES
    title?: string
    onClick?: Function
}

// New types needs to be checked in FontAwesome kit to prevent useless font imports
// https://fontawesome.com/kits/dd60f349d9/settings
const TYPES = {
    solid: 'fa-solid',
    regular: 'fa-regular',
    light: 'fa-light',
    sharp: 'fa-sharp',
    duotone: 'fa-duotone',
    brands: 'fa-brands',
}

export const SIZES = {
    xxs: 'text-[0.5rem]',
    xs: 'text-xs',
    sm: 'text-sm',
    md: 'text-base',
    lg: 'text-lg',
    xl: 'text-xl',
    '2xl': 'text-2xl',
    '4xl': 'text-4xl',
    '6xl': 'text-6xl',
    '8xl': 'text-8xl',
}

const ICON_LIB_SCRIPT_URL = 'https://kit.fontawesome.com/dd60f349d9.js'
const ICON_LIB_SCRIPT_ID = 'icon_lib_script_id'

const AppIcon = ({className, iconClassName, hoveredClassName = '', type = 'solid', size = 'md', title = undefined}: AppIconProps) => {
    const [isScriptLoaded, setScriptLoaded] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    useEffect(() => {
        if (document.getElementById(ICON_LIB_SCRIPT_ID) === null) {
            const script = document.createElement('script')

            script.src = ICON_LIB_SCRIPT_URL
            script.async = true
            script.id = ICON_LIB_SCRIPT_ID
            script.onload = () => {
                setScriptLoaded(true)
            };
            document.body.appendChild(script)
        } else {
            setScriptLoaded(true)
        }
        return () => {}
    }, [])

    return (
        <i
            className={`${SIZES[size]} inline-block text-center leading-[1em] ${className}`}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            {isScriptLoaded
                ? <i className={`${TYPES[type]} h-full w-full ${iconClassName} ${isHovered && hoveredClassName}`} title={title}/>
                : <Skeleton containerClassName={`inline-block h-full w-full font-black`} />}
        </i>
    );
};

export default AppIcon;
