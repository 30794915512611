import { useState } from "react";
import Image from "next/image";
import {GenericInputProps} from "@/app/components/atomic/Input/AppInput";

// TODO: Proper typing

export default function SimpleSelectInput(props: GenericInputProps) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(props.value || '');

    const handleOptionClick = (option: any) => {
        setSelectedOption(option.value);
        setDropdownOpen(false);
        props.onChange({ value: option.value, fieldName: props.fieldName });
    };

    return (
        <div className="w-full relative">
            {/* Hidden native select for SEO */}
            <select
                className="hidden"
                value={selectedOption}
                onChange={(e) => handleOptionClick(props.inputProps.options.find((opt: any) => opt.value === e.target.value))}
            >
                <option value=''>Je recherche une mutuelle pour</option>
                {props.inputProps.options.map((option: any) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>

            {/* Custom styled dropdown */}
            <div
                className="w-full h-[50px] flex items-center px-4 text-blue-600 border rounded cursor-pointer"
                onClick={() => setDropdownOpen(!dropdownOpen)}
            >
                {selectedOption ?
                    props.inputProps.options.find((option: any) => option.value === selectedOption).label :
                    'Je recherche une mutuelle pour'}

                {/* Chevron icon */}
                <span className={`ml-auto text-rm-16 text-blue-600 ${dropdownOpen ? 'fas fa-chevron-up' : 'fas fa-chevron-down fa-bounce'}`} />
            </div>

            {/* Dropdown options list */}
            {dropdownOpen && (
                <ul className="absolute bottom-full rm-md:top-full rm-md:h-fit mb-1 rm-md:mb-0 rm-md:mt-1 w-full bg-white border border-blue-300
                border-b-0 rounded z-10">
                    {props.inputProps.options.map((option: any) => (
                        <li
                            key={option.value}
                            className={`cursor-pointer flex flex-col text-blue-900 rounded
                                hover:bg-blue-600 hover:text-white group user-select-none border-b border-b-blue-300
                            `}
                            onClick={() => handleOptionClick(option)}
                        >
                            <div className="inline-flex gap-rm-20 items-center px-rm-15 py-rm-10">
                                <span className="h-[50px] w-[50px] bg-blue-100 group-hover:bg-[#0B61BC] rounded">
                                    <Image
                                        className="object-contain"
                                        src={option.icon}
                                        alt="Couple"
                                        width={50}
                                        height={50}
                                    />
                                </span>
                                {option.label}
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}
