import React, {useState, useEffect, ChangeEvent, useCallback, ChangeEventHandler, Ref, useRef} from 'react';
import {useFormContext} from "react-hook-form";
import dynamic from "next/dynamic";
import {GenericInputProps} from "@/app/components/atomic/Input/AppInput";
import {InputMaskProps, MaskProps} from "@react-input/mask";

interface TextInputProps extends GenericInputProps {
    required?: boolean;
    debounce?: number | null;
    placeholder?: string;
    validation?: boolean;
    inputRef?: Ref<any>;
    className?: string;
    mask?: InputMaskProps["mask"];
    replacement?: InputMaskProps["replacement"];
    track?: InputMaskProps["track"];
}

export default function TextInput({
    value,
    onChange,
    fieldName,
    required,
    debounce,
    placeholder,
    validation = undefined,
    inputRef,
    className = '',
    mask,
    replacement,
    track
}: TextInputProps) {
    const [internalValue, setInternalValue] = useState<string>(String(value));
    useEffect(() => {
        setInternalValue(String(value));
    }, [value]);

    const debouncedOnChange = useCallback(
        (e: ChangeEvent<HTMLInputElement>) => {
            const rawValue = e.target.value;
            setInternalValue(rawValue);

            const handler = setTimeout(() => {
                onChange({
                    value: e.target.value,
                    fieldName,
                });
            }, debounce || 0);

            return () => {
                clearTimeout(handler);
            };
        },
        [onChange, debounce, value]
    );

    const inputClassName = `flex-grow h-full px-4 py-2 outline-none ${className}`
    return (
        <>
            {validation
                ? <TextInputWithValidation
                    value={internalValue}
                    onChange={onChange}
                    internalValue={internalValue}
                    debouncedOnChange={debouncedOnChange}
                    fieldName={fieldName}
                    validation={validation}
                    required={required}
                    placeholder={placeholder}
                    inputRef={inputRef}
                    mask={mask}
                    replacement={replacement}
                    track={track}
                    className={inputClassName}
                />
                :<input
                ref={inputRef}
                type="text"
                value={internalValue}
                onChange={debouncedOnChange}
                name={fieldName}
                required={required}
                className={inputClassName}
                placeholder={placeholder}
            />}
        </>
    );
}

const InputMask = dynamic(() => import('@react-input/mask').then(mod => mod.InputMask), { ssr: false });

function TextInputWithValidation(props: TextInputProps & { internalValue: string, debouncedOnChange: ChangeEventHandler }) {
    const { register, formState: { errors }} = useFormContext()
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        props.debouncedOnChange(e)
    }

    if (props.mask) {
        return <InputMask
            mask={props.mask}
            replacement={props.replacement}
            track={props.track}
            value={props.internalValue}
            required={props.required}
            className={props.className}
            placeholder={props.placeholder}
            {...register(props.fieldName as string)}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleOnChange(e)}
        />
    }


    return <input
            type="text"
            value={props.internalValue}
            required={props.required}
            className={props.className}
            placeholder={props.placeholder}
            {...register(props.fieldName as string)}
            onChange={(e) => handleOnChange(e)}
        />
}
