import Select, {components as selectComponents} from "react-select";
import {GenericInputProps} from "@/app/components/atomic/Input/AppInput";
import {Controller, useFormContext} from "react-hook-form";
import {useEffect, useState} from "react";

function Menu(props: any) {
    return <selectComponents.Menu {...props} className={'absolute bg-white w-full left-0 rounded-b border border-blue-300 '}>
        {props.options.map((option: any) => <MenuOption onClick={() => {
            props.setValue(option, 'set-value')
        }} key={option.label} option={option} />)}
    </selectComponents.Menu>
}

function MenuOption({ option, onClick }: any) {
    return <div onClick={() => onClick()} className={"px-4 py-2 cursor-pointer hover:bg-blue-600 hover:text-white"}>
        {option.label}
    </div>
}

interface SelectProps extends GenericInputProps {
    inputRef?: any;
}
export default function SelectInput (props: SelectProps) {
    const { options } = props.inputProps;
    const { control, formState: { errors }} = useFormContext()
    useEffect(() => {
        console.log("errors", errors);
    }, [errors]);

    return <Controller
            control={control}
            render={({field}) => (
                <Select
                    placeholder={"Selectionnez..."}
                    className={"w-full static"}
                    unstyled={true}
                    hideSelectedOptions={true}
                    components={{
                        Menu,
                    }}
                    name={props.fieldName}
                    noOptionsMessage={() => 'Aucune option disponible'}
                    getOptionValue={(option) => option?.value}
                    isSearchable={false}
                    value={options.find((option: typeof props.inputProps.options) => option.value === props.value)}
                    onChange={(val) => {
                        props.onChange(val)
                    }}
                    options={props.inputProps.options}
                ></Select>
            )}
            name={props.fieldName}

    />
}
