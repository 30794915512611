import DatePicker, {ReactDatePickerProps} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "@/app/styles/components/date-picker.scss";
import {fr} from 'date-fns/locale/fr';

interface DatePickerProps {
    value: string;
    minDate?: string;
    maxDate?: string;
    onChange: any;
    className?: string;
    wrapperClassName?: string;
    displayIcon?: boolean;
    useIso?: boolean;
    filterDate?: ReactDatePickerProps['filterDate'];
}

export default function AppDatePicker({value, minDate, maxDate, onChange, displayIcon = false, useIso = false, filterDate = (date) => true, className = '', wrapperClassName = ''}: DatePickerProps) {
    const handleChange = (date: Date) => {
        onChange(useIso ? date.toISOString().split('T')[0] : date.toLocaleDateString('fr-FR'));
    }

    const parseDate = (dateString: string) => {
        if (!dateString) return new Date();
        if (typeof dateString === 'object') return dateString;
        if (useIso) return new Date(dateString)
        const [day, month, year] = dateString.split('/');
        return new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    }

    let minDateObj = minDate ? parseDate(minDate) : undefined;
    let maxDateObj = maxDate ? parseDate(maxDate) : undefined;

    const isDateBlocked = (date: any) => {
        return !filterDate(date)
    }

    return (
        <div
            className={`flex items-center border border-blue-300 rounded overflow-hidden w-full h-10 transition-all
                duration-500 hover:border-blue-500 focus-within:border-blue-500 ${wrapperClassName}`}
        >
            <DatePicker
                selected={value ? parseDate(value) : undefined}
                onChange={handleChange}
                minDate={minDateObj}
                maxDate={maxDateObj}
                dateFormat="dd/MM/yyyy"
                dateFormatCalendar="LLLL yyyy"
                filterDate={filterDate}
                locale={fr}
                className={`flex w-full p-2 focus:outline-none ${className}`}
                calendarClassName="rounded-lg border border-blue-300"
                dayClassName={(date) => {
                    return isDateBlocked(date) ? 'bg-blue-200 opacity-50' : ''
                }}
                wrapperClassName="w-full"
            />

            {displayIcon && (
            <div className="flex items-center justify-center h-10 px-2 bg-blue-100 text-sm font-semibold border-l border-blue-300">
                <i className="fas fa-calendar text-blue-700" />
            </div>
            )}
        </div>
    )
}
